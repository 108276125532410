<ng-container #content></ng-container>

@if (renderSpinner) {
    <div
        class="ngx-lazy-loader-distractor"
        [class.destroying]="isClearingLoader"
    >
        @if (config.loaderDistractorComponent) {
            <ng-container
                [ngComponentOutlet]="config.loaderDistractorComponent"
            />
        }
        @if (config.loaderDistractorTemplate) {
            <ng-container
                [ngTemplateOutlet]="config.loaderDistractorTemplate"
                [ngTemplateOutletContext]="{ '$implicit': inputs }"
            />
        }
    </div>
}
